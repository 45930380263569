<template>
	<div class="main">
		<!-- <div class="location">
			<div class="cur-location">
				<span style="line-height: 1">您所在的位置：</span>
			</div>
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item>系统设置</el-breadcrumb-item>
				<el-breadcrumb-item>滚动广播管理</el-breadcrumb-item>
			</el-breadcrumb>
		</div> -->
		<common-header :formModel="formInline" @query="query" class="common_header">
			>
			<template v-slot:form>
				<el-form-item :label="$t('banner.title')" prop="param">
					<el-input v-model="formInline.param"></el-input>
				</el-form-item>
			</template>
			<template v-slot:tools>
				<tools-btn left-icon="add" @click.native="add()">{{ $t('common.add') }}</tools-btn>
			</template>
		</common-header>
		<div class="table tableHeight">
			<el-table border stripe size="mini" height="100%" :data="tableData">
				<el-table-column show-overflow-tooltip prop="title" :label="$t('scrollBar.title')"></el-table-column>
				<el-table-column show-overflow-tooltip prop="sort" :label="$t('scrollBar.sort')"></el-table-column>
				<el-table-column show-overflow-tooltip prop="isUp" :label="$t('common.isUp')">
					<template v-slot="{ row }">
						{{ row.isUp ? $t('common.up') : $t('common.down') }}
					</template>
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="beginUseTime" :label="$t('scrollBar.beginUseTime')">
					<template v-slot="{ row }">
						{{ row.beginUseTime | parseTime('{y}-{m}-{d} {h}:{i}:{s}') }}
					</template>
				</el-table-column>
				<el-table-column show-overflow-tooltip prop="endUseTime" :label="$t('scrollBar.endUseTime')">
					<template v-slot="{ row }">
						{{ row.endUseTime | parseTime('{y}-{m}-{d} {h}:{i}:{s}') }}
					</template>
				</el-table-column>

				<el-table-column :label="$t('common.operation')" width="140">
					<template v-slot="{ row }">
						<el-button size="mini" type="text" @click="edit(row)">
							{{ $t('common.edit') }}
						</el-button>
						<el-button size="mini" type="text" @click="isUp(row)">
							{{ row.isUp ? $t('common.down') : $t('common.up') }}
						</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<gl-page
			@size-change="sizeChange"
			@current-change="query"
			:current-page="formInline.pageNum"
			:page-size="formInline.pageSize"
			:total="total"
		></gl-page>
		<el-dialog :title="title" :visible.sync="addVisible" width="730px" center :close-on-click-modal="false">
			<el-form ref="addForm" :model="addForm" label-width="120px" class="form" :rules="rules">
				<el-form-item :label="$t('scrollBar.title')" prop="title">
					<el-input size="mini" v-model="addForm.title"></el-input>
				</el-form-item>
				<el-form-item :label="$t('scrollBar.sort')" prop="sort">
					<el-input size="mini" v-model="addForm.sort"></el-input>
				</el-form-item>
				<el-form-item :label="$t('scrollBar.beginUseTime')" prop="beginUseTime">
					<el-date-picker
						v-model="addForm.beginUseTime"
						type="datetime"
						placeholder="选择开始时间"
						size="mini"
					></el-date-picker>
				</el-form-item>
				<el-form-item :label="$t('scrollBar.endUseTime')" prop="endUseTime">
					<el-date-picker
						v-model="addForm.endUseTime"
						type="datetime"
						placeholder="选择结束时间"
						size="mini"
					></el-date-picker>
				</el-form-item>
				<el-form-item :label="$t('scrollBar.isUp')" prop="isUp">
					<el-switch v-model="addForm.isUp"></el-switch>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="addVisible = false">取 消</el-button>
				<el-button type="primary" @click="save">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
export default {
	data() {
		return {
			total: 0,
			tableData: [],
			formInline: {
				pageNum: 1,
				pageSize: 10,
				param: ''
			},
			addForm: {
				beginUseTime: '',
				endUseTime: '',
				id: '',
				isUp: true,
				sort: '',
				title: ''
			},
			addVisible: false,
			rules: {
				title: [{ required: true, message: '请输入标题', trigger: 'blur' }],
				beginUseTime: [{ required: true, message: '请选择有效开始时间', trigger: 'blur' }],
				endUseTime: [{ required: true, message: '请选择有效结束时间', trigger: 'blur' }],
				isUp: [{ required: true, message: '请选择是否上下架', trigger: 'blur' }]
			},
			title: ''
		}
	},
	mounted() {
		this.query()
	},
	methods: {
		edit(row) {
			this.addVisible = true
			this.title = this.$t('common.edit')
			Object.assign(this.addForm, row)
		},
		isUp(row) {
			console.log(row)
			this.ajax.post('/api/scroll/isUp', { id: row.id, operator: !row.isUp }).then(() => {
				this.$message.success(this.$t('common.success'))
				this.query()
			})
		},
		save() {
			this.$refs.addForm.validate(valid => {
				if (valid) {
					this.ajax.post('api/scroll/saveScroll', this.addForm).then(() => {
						this.addVisible = false
						this.query()
					})
				}
			})
		},
		add() {
			this.addVisible = true
			this.title = this.$t('common.add')
			this.addForm = {
				beginUseTime: '',
				endUseTime: '',
				id: '',
				isUp: true,
				sort: '',
				title: ''
			}
		},
		query(i) {
			if (isNaN(i)) {
				i = 1
			}
			this.formInline.pageNum = i
			this.ajax.post('/api/scroll/scrollList', this.formInline).then(res => {
				console.log(res)
				this.tableData = res.list
				this.total = res.total
			})
		},
		sizeChange(i) {
			this.formInline.pageSize = i
			this.query()
		}
	}
}
</script>
<style lang="scss" scoped>
::v-deep .el-select {
	width: 218px;
}
</style>
